import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllStaticPages } from "../../store/StaticPages/actions";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

function ContentFooter(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllStaticPages());
    }, []);

    const PageTypes = useSelector((state) => state?.staticPages.Index ?? []).map(
        (sp) => sp["page_type"]
    );

    const fb_style = { color: "Blue", fontSize: "2.5em" }
    const in_style = { color: "orange", fontSize: "2.5em" }
    const tw_style = { color: "light blue", fontSize: "2.5em" }

    return (
        <>
            <div className="BLM-footer">
                <div className="BLM-footer-container">
                    <div className="BLM-footer-logo"></div>
                    <div className="BLM-footer-linksList">
                        <ul>
                            {PageTypes.map((pt, index) => (
                                <li key={index}>
                                    <Link to={pt?.replace(/ /g, "-")}>{pt}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="BLM-divider"></div>
                    <p>BetSports is a fully registered company licensed by the National Gaming and Regulatory Board under License Number NLGRB-BM-25-0088. We are located on Rovis complex Plot 49, Ntinda Road, Kampala-Uganda. Postal address is P.O Box, 520023, Luzira-Uganda.</p>
                    <p>Telephone contacts: 077 622 5922/ 075 394 1009/ 039 325 6780</p>
                    <p>Email: info@betsports.co.ug, Customer Service: cs@betsports.co.ug</p>
                    <p>You have to be 25+ years and above to bet</p>
                    <p>Betting is addictive and can be psychologically harmful.</p>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <a href="https://www.facebook.com/BetsportsUganda" target="_blank" rel="noopener noreferrer">
                            <BsFacebook style={fb_style} />
                        </a>

                        <a href="https://instagram.com/betsportsug?igshid=ZDdkNTZiNTM=" target="_blank" rel="noopener noreferrer">
                            <BsInstagram style={in_style} />
                        </a>

                        <a href="https://twitter.com/BetsportsUganda" target="_blank" rel="noopener noreferrer">
                            <BsTwitter style={tw_style} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContentFooter;
